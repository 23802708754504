<template>
  <div class="find-container relative flex flex-col justify-end px-8">
    <div class="absolute z-10 top-6 left-1/2 transform -translate-x-1/2 cursor-pointer" @click="goToEnigma()">
      <img src="../assets/icons/close.svg" alt="">
    </div>
    
    <div class="h-4/6 sm:h-3/5">
      <ValidationObserver
        v-slot="{handleSubmit}"
        ref="codeObserver"
        tag="form"
        @submit.prevent
      >
        <div class="sm:w-64 sm:mx-auto delay-1000 transition duration-1000" :class="{'transform -translate-y-60 sm:-translate-y-96 opacity-0': isCodeFound}">
          <p class="text-lg">Did you find a code?</p>
          <NText
            id="input-code"
            :name="'code'"
            type="text"
            v-model="code"
            rules="required|alphaNum"
            animationType="above"
            errorTrigger="blur"
            designType="line"
            @input="resetLoaderButton()"
            :textColor="colorStyle.quinary"
            :backgroundColor="'transparent'"
            :borderColor="'transparent'"
            :errorColor="colorStyle.error"
            :showIcons="false"
            :placeholder="'My code...'"
          ></NText>
          <!-- HIDDEN CTALOADER FOR ENTER KEY FIX -->
          <NCtaLoader
            ref="myCtaLoaderFind"
            class="hidden"
            @click.native="handleSubmit(submitCode)"
          >
            SUBMIT MY CODE
          </NCtaLoader>
        </div>
        <div class="sm:w-5/6 sm:w-4/6 sm:mx-auto delay-1000 transition duration-1000 relative " :class="{'transform -translate-y-72 sm:-translate-y-96': isCodeFound, 'opacity-0': !isCodeFound}">
          
          <div class="code-fade-top z-10 transition duration-1000 opacity-0" :class="{'opacity-100': isCodeFound}"></div>
          <div class="code-fade-bottom z-10 transition duration-1000 opacity-0" :class="{'opacity-100': isCodeFound}"></div>
          <div class="relative code-reveal py-10 transition duration-1000" :class="{'opacity-0': !isCodeFound}">
            <p class="text-lg mb-4">Code <span v-if="codeFoundId">{{codeFoundId == 6 || codeFoundId == 7 ? '6 & 7' : codeFoundId}}</span></p>
            <p class="text-xl md:text-xxl" v-if="oResponses.iQuestion_206 && oResponses.iQuestion_207 && (codeFoundId == 6 || codeFoundId == 7)" v-html="codeExplanations[codeFoundId-1].completeText"></p>
            <p class="text-xl md:text-xxl" v-else-if="codeFoundId" v-html="codeExplanations[codeFoundId-1].text"></p>
          </div>
        </div>
        <div :class="[isCodeFound ? 'z-20 opacity-1': 'z-10 opacity-0']" class="absolute bottom-32 sm:bottom-24 left-1/2 transition delay-1000 duration-1000 transform -translate-x-1/2 flex justify-center">
          <button class="button-code border-solid border-quinary text-sm cursor-pointer overflow-hidden" @click="goToEnigma(true)">
            <span class="block transition delay-1000 duration-1000 transform" :class="{'-translate-y-10': !isCodeFound }">CONTINUE</span>
          </button>
        </div>
        <div :class="[!isCodeFound ? 'z-20 opacity-1': 'z-10 opacity-0']" class="absolute z-10 bottom-32 sm:bottom-24 left-1/2 transition delay-1000 duration-1000 transform -translate-x-1/2 flex justify-center">
          <NCtaLoader
            ref="myCtaLoaderFind"
            class="my-cta-loader transition duration-1000"
            :class="{'opacity-50': !code}"
            :borderColor="{ default: colorStyle.quinary, hover: colorStyle.quinary, success: colorStyle.quinary, error: colorStyle.error }"
            :background-color="{ default: 'transparent', hover: 'transparent', success: colorStyle.quinary, error: colorStyle.error }"
            @click.native="handleSubmit(submitCode)"
          >
            SUBMIT MY CODE
          </NCtaLoader>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import design from '@/validators/design'
import { NText } from '@team-uep/n-inputs'
import NCtaLoader from '@/components/NCtaLoader.vue'
import { codeExplanations } from '../data'
import { mapGetters } from "vuex";
export default {
  name: 'Find',
  components: { 
    NText,
    NCtaLoader
  },
  computed: {
    ...mapGetters({
      lastEnigmaId: "user/lastEnigmaId",
      oResponses: "user/responses"
    }),
    isCodeFound () {
      return this.codeFoundId && this.codeFound
    }
  },
  data () {
    return {
      code: '',
      codeFound: false,
      codeFoundId: 0,
      isLoading: false,
      colorStyle: design.colors,
      panoplyStyle: design.panoply,
      codeExplanations: codeExplanations
    }
  },
  mounted () {
    if (this.$route.params.idEnigma && this.$route.params.resolved) {
      this.codeFound = true
      this.codeFoundId = parseInt(this.$route.params.idEnigma)
    }
  },
  methods: {
    goToEnigma(goToNextEnigma) {
      if (this.$route.params.fromName && !this.isCodeFound) {
        this.$router
          .push({ name: this.$route.params.fromName })
          .catch(console.log)
      } else {
        let indexToGo = this.lastEnigmaId
        if (goToNextEnigma) {
          indexToGo = this.lastEnigmaId != 13 ? this.lastEnigmaId + 1 : this.lastEnigmaId
        }
        this.$router
          .push({ name: 'hub', params: { swiperIndex: indexToGo || '1' } })
          .catch(console.log)
      }
    },
    resetLoaderButton() {
      this.$refs.myCtaLoaderFind.reset()
      this.isLoading = false
    },
    resetForm() {
      this.code = ''
      this.$nextTick(() => {
        this.$refs.codeObserver.reset()
      })
      this.resetLoaderButton()
      this.codeFound = false
      setTimeout(() => {
        this.codeFoundId = 0
      }, 1000)
    },
    submitCode() {
      // Start the loader
      if (!this.isLoading) {
        this.isLoading = true
        this.$refs.myCtaLoaderFind.startLoading()
        this.$requestAPI
          .qualifRegister({
            oResponses : { 
              sCode: this.code.toLowerCase(),
            }
          }).then((r)=>{
            console.log('res', r)
            
            this.$refs.myCtaLoaderFind.endLoading(true)
              .then(() => {
                this.$gtmTracking.keyFound()
                this.$requestAPI
                  .getSessionCurrent()
                  .then(() => {
                    this.isLoading = false
                    this.codeFound = true
                    this.codeFoundId = this.codeExplanations.filter((item) => {
                      return item.code === this.code.toLowerCase()
                    })[0].id
                    console.log('this.codeFoundId', this.codeFoundId)
                  })
                  .catch(console.log);
                
              })
          })
          .catch((error)=> {
            console.log('error', error)
            this.$refs.myCtaLoaderFind.endLoading(false, 'Invalid code')
              .then(() => {
                this.isLoading = false
                this.codeFound = false
                this.codeFoundId = 0
              })
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .find-container {
    background: url('../assets/bg/share-found-min.jpg');
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    @media screen and (min-width: 768px) {
      background: url('../assets/bg/share-found.jpg');
      background-size: cover;
    }
    .code-reveal {
      height: 60vh;
      @media screen and (min-width: 768px) {
        height: 65vh;
      }
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .code-fade-top, .code-fade-bottom {
      position: absolute;
      width: 100%;
      height: 10%;
    }
    .code-fade-top {
      top: 0;
      background: linear-gradient(to bottom, rgba(10,10,10,1) 20%,rgba(0,0,0,0) 100%);
      @media screen and (min-width: 768px) {
        background: linear-gradient(to bottom, rgba(10,10,10,1) 5%,rgba(0,0,0,0) 100%);
      }
    }
    .code-fade-bottom {
      bottom: 0;
      background: linear-gradient(to top, rgba(10,10,10,1) 20%,rgba(0,0,0,0) 100%);
      @media screen and (min-width: 769px) {
        background: linear-gradient(to top, rgba(10,10,10,1) 5%,rgba(0,0,0,0) 100%);
      }
    }
    .button-code {
      border-width: 1px;
      width: 198px;
      outline: none;
      padding: 0.8em 1.3em;
    }
  }
  ::v-deep .n-input.n-input-line div input {
    font-size: 28px;
    border: none;
  }
  ::v-deep .my-cta-loader {
    width: 198px;
    font-size: 14px;
    position: relative;
    .n-cta-loader {
      padding: 0.8em 1.3em;
      &.is-loading:not(.is-completed) .n-cta-loader__progress-bar {
        background-color: rgb(232, 206, 164) !important;
      }
      &.is-loading:not(.is-completed) .n-cta-loader__content {
        color: black;
      }
    }
    .error-text {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      bottom: -25px;
    }
    .is-completed.error {
      border-color: rgb(255, 59, 48) !important;
    }
  }
</style>
