var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{class:[
      'n-cta-loader',
      _vm.disabled ? 'is-disabled' : '',
      !_vm.outline ? 'outline' : '',
      _vm.loading ? 'is-loading' : '',
      _vm.completed && !_vm.hasError ? 'is-completed valid' : '',
      _vm.completed && _vm.hasError ? 'is-completed error' : ''
    ],style:(Object.assign({}, {'border-color' : ("" + _vm.borderColors),
      'background-color':  _vm.hover ? _vm.backgroundColors.hover : _vm.backgroundColors.default},
      (_vm.borderRadius && {'border-radius' : _vm.borderRadius}))),attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')},"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"loading":function($event){return _vm.startLoading()},"completed":function($event){return _vm.endLoading(_vm.isValid)}}},[_c('div',{staticClass:"n-cta-loader__progress-bar",style:({
          'background-color': _vm.completed && !_vm.hasError ? _vm.backgroundColors.success : _vm.completed && _vm.hasError ? _vm.backgroundColors.error : 'rgba(0,0,0,0.4)',
          'animation-duration': _vm.completed ? '0.3s' : ((_vm.loaderDuration/1000) + "s")
        })}),_c('div',{staticClass:"n-cta-loader__content"},[_c('div',{class:['text', _vm.completed ? 'fade-out' : '']},[_vm._t("default")],2),_c('div',{class:[_vm.completed ? 'fade-in' : '']},[(_vm.hasError)?_c('div',{staticClass:"error"},[_c('errorSVG')],1):_c('div',{staticClass:"success"},[_c('checkSVG')],1)])])]),(_vm.errorMessage && _vm.hasError)?_c('div',{staticClass:"error-text",style:({
      'color' : ("" + (_vm.textColor.error)),
    }),domProps:{"innerHTML":_vm._s(_vm.errorMessage)}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }