<template>
  <div>
    <button
      :disabled="disabled"
      :class="[
        'n-cta-loader',
        disabled ? 'is-disabled' : '',
        !outline ? 'outline' : '',
        loading ? 'is-loading' : '',
        completed && !hasError ? 'is-completed valid' : '',
        completed && hasError ? 'is-completed error' : ''
      ]"
      @click="$emit('click')"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @loading="startLoading()"
      @completed="endLoading(isValid)"
      :style="{
        'border-color' : `${borderColors}`,
        'background-color':  hover ? backgroundColors.hover : backgroundColors.default,
        ...(borderRadius && {'border-radius' : borderRadius})
      }">
        <div
          class="n-cta-loader__progress-bar"
          :style="{
            'background-color': completed && !hasError ? backgroundColors.success : completed && hasError ? backgroundColors.error : 'rgba(0,0,0,0.4)',
            'animation-duration': completed ? '0.3s' : `${loaderDuration/1000}s`
          }"
        ></div>
        <div class="n-cta-loader__content">
          <div :class="['text', completed ? 'fade-out' : '']">
            <slot></slot>
          </div>
          <div :class="[completed ? 'fade-in' : '']">
            <div v-if="hasError" class="error">
              <errorSVG />
            </div>
            <div v-else class="success">
              <checkSVG />
            </div>
          </div>
        </div>
    </button>
    <div
      v-if="errorMessage && hasError"
      class="error-text"
      v-html="errorMessage"
      :style="{
        'color' : `${textColor.error}`,
      }"
    >
    </div>
  </div>
</template>

<script>
import errorSVG from './NIconError.vue'
import checkSVG from './NIconCheck.vue'
const primaryColor = '#00A6B6'
const successColor = '#79E38B'
const errorColor = '#FF3B30'

export default {
  name: 'NCtaLoader',
  components: {
    checkSVG,
    errorSVG
  },
  props: {
    loaderDuration: {
      type: Number,
      required: false,
      default: 1500
    },
    borderRadius: {
      type: String,
      default: '0'
    },
    // différent state of n-cta//
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    textColor: {
      required: false,
      default: () => {
        return {
          default: 'white',
          error: '#FF3B30'
        }
      }
    },
    borderColor: {
      required: false,
      default: () => {
        return {
          default: ''
        }
      }
    },
    backgroundColor: {
      required: false,
      default: () => {
        return {
          default: primaryColor,
          hover: primaryColor,
          success: successColor,
          error: errorColor
        }
      }
    },
    outline: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      hover: false,
      loading: false,
      completed: false,
      hasError: false,
      errorMessage: '',
      isAnimDone: false,
      errorSVG,
      checkSVG
    }
  },
  computed: {
    backgroundColors () {
      // const { backgroundColor } = this.$props
      return this.formatColor(this.backgroundColor)
    },
    textColors () {
      const color = this.formatColor(this.textColor)
      return (
        (this.disabled && color.disabled) ||
        (this.hover && color.hover) ||
        (this.hasError && color.error) ||
        color.default
      )
    },
    borderColors () {
      const border = this.formatColor(this.borderColor)
      return (
        (this.disabled && border.disabled) ||
        (this.hover && border.hover) ||
        border.default
      )
    }
  },

  methods: {
    formatColor (value) {
      if (typeof value === 'string') {
        if (value.match(/^\{[\s+|\w|\W|\s]+\}$/gm)) {
          value = JSON.stringify(value)
          value = JSON.parse(value)
        } else {
          return { default: value }
        }
      }
      return value
    },
    reset () {
      this.loading = false
      this.completed = false
      this.hasError = false
      this.isAnimDone = false
      this.errorMessage = ''
    },
    startLoading () {
      this.reset()
      this.loading = true
      setTimeout(() => {
        this.isAnimDone = true
      }, this.loaderDuration)
    },
    endLoading (isValid, msg) {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.isAnimDone) {
            this.completed = true
            this.hasError = !isValid
            this.errorMessage = msg || ''

            clearInterval(interval)
            resolve()
          }
        }, 200)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

// SCSS style with BEM and OOCSS programming paradigm
.error-text {
  width: 100%;
  text-align: center;
  margin-top: 0.4em;
}

.n-cta-loader {
  cursor: pointer;
  padding: 1.3em 3.3em;
  border: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  border: 1px solid;

  &.is-disabled {
    cursor: not-allowed;
  }

  &.outline {
    &:focus {
      outline: none;
    }
  }

  &.is-loading {
    .n-cta-loader__progress-bar {
      animation: filling linear forwards running;
    }
  }

  &.is-completed {
    .n-cta-loader__progress-bar {
      animation: completed linear forwards;
      transition: width 0.3s;
      width: 100%;
    }
  }

  &__progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    animation: filling linear forwards paused;
  }

  &__content {
    position: relative;
    color: #FFF;
    text-align: center;

    .text {
      width: 100%;
      &.fade-out {
        transition: all 0.5s;
        transform: translateY(-30px);
        opacity: 0;
      }
    }
    .success, .error {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      transition: all 0.5s;
      opacity: 0;
      transform: translateY(30px);
      > * {
        margin: auto;
      }
    }
    .fade-in {
      .success, .error {
        opacity: 1;
        transform: translateY(-25%);
      }
    }
  }
}
@keyframes filling {
  0%   {width: 0%;}
  33%  {width: 50%;}
  66%  {width: 75%;}
  100%  {width: 87.5%;}
}
@keyframes completed {
  from {width: 87.5%;}
  to {width: 100%;}
}
</style>
